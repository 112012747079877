const RoleFieldType = {
  GenderType: 0,
  BirthDate: 1,
  IdentityNumber: 2,
  AadharNumber: 3,
  DiseaseTypes: 4,
  Address: 5,
  SecondaryAddress: 6,
  SecondaryContactName: 7,
  SecondaryContactPhone: 8,
  TherapeuticAreaType: 9,
  CegedimId: 10,
  Institution: 11,
  UciCode: 12,
  CrmId: 13,
  Weight: 14,
  InstitutionAddress: 15,
  IndividualsSalutationPreFix: 16,
  ConsentExpirationDate: 17,
  Phone: 18,
  Email: 19,
  ExternalId: 20,
  Region: 21
};

export default RoleFieldType;