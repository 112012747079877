const RoleFieldTypes = [
  {
    value: 0,
    name: 'GenderType',
    displayName: 'GenderType',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 100,
    data: {
      group: 0
    }
  },
  {
    value: 1,
    name: 'BirthDate',
    displayName: 'BirthDate',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 200,
    data: {
      group: 2
    }
  },
  {
    value: 2,
    name: 'IdentityNumber',
    displayName: 'IdentityNumber',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 300,
    data: {
      group: 4
    }
  },
  {
    value: 3,
    name: 'AadharNumber',
    displayName: 'AadharNumber',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 400,
    data: {
      group: 4
    }
  },
  {
    value: 4,
    name: 'DiseaseTypes',
    displayName: 'DiseaseTypes',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 500,
    data: {
      group: 1
    }
  },
  {
    value: 5,
    name: 'Address',
    displayName: 'Address',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 600,
    data: {
      group: 5
    }
  },
  {
    value: 6,
    name: 'SecondaryAddress',
    displayName: 'SecondaryAddress',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 700,
    data: {
      group: 5
    }
  },
  {
    value: 7,
    name: 'SecondaryContactName',
    displayName: 'SecondaryContactName',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 800,
    data: {
      group: 5
    }
  },
  {
    value: 8,
    name: 'SecondaryContactPhone',
    displayName: 'SecondaryContactPhone',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 900,
    data: {
      group: 6
    }
  },
  {
    value: 9,
    name: 'TherapeuticAreaType',
    displayName: 'TherapeuticAreaType',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 1000,
    data: {
      group: 0
    }
  },
  {
    value: 10,
    name: 'CegedimId',
    displayName: 'CegedimId',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 1100,
    data: {
      group: 5
    }
  },
  {
    value: 11,
    name: 'Institution',
    displayName: 'Institution',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 1200,
    data: {
      group: 5
    }
  },
  {
    value: 12,
    name: 'UciCode',
    displayName: 'UciCode',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 1300,
    data: {
      group: 5
    }
  },
  {
    value: 13,
    name: 'CrmId',
    displayName: 'CrmId',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 1400,
    data: {
      group: 5
    }
  },
  {
    value: 14,
    name: 'Weight',
    displayName: 'Weight',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 1500,
    data: {
      group: 7
    }
  },
  {
    value: 15,
    name: 'InstitutionAddress',
    displayName: 'InstitutionAddress',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 1600,
    data: {
      group: 5
    }
  },
  {
    value: 16,
    name: 'IndividualsSalutationPreFix',
    displayName: 'IndividualsSalutationPreFix',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 1700,
    data: {
      group: 5
    }
  },
  {
    value: 17,
    name: 'ConsentExpirationDate',
    displayName: 'ConsentExpirationDate',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 1800,
    data: {
      group: 2
    }
  },
  {
    value: 18,
    name: 'Phone',
    displayName: 'Phone',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 20,
    data: {
      group: 6
    }
  },
  {
    value: 19,
    name: 'Email',
    displayName: 'Email',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 10,
    data: {
      group: 8
    }
  },
  {
    value: 20,
    name: 'ExternalId',
    displayName: 'ExternalIdentityNumber',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 1900,
    data: {
      group: 5
    }
  },
  {
    value: 21,
    name: 'Region',
    displayName: 'Region',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 2000,
    data: {
      group: 5
    }
  }
];

export default RoleFieldTypes;