import { InboxOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
import 'cropperjs/dist/cropper.css';
import { useRef, useState } from 'react';
import { Cropper } from 'react-cropper';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ValidFileTypes } from '../../../constants';
import { convertFileToUrl } from '../../../utilies/fileHelper';
import { Modal } from '../../globals';

const { Dragger } = Upload;

export default function FileInputBase({ mimeTypes, files, setFiles, multiple }) {
  const { t } = useTranslation();

  const imageRef = useRef(null);
  const [file, setFile] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [cropper, setCropper] = useState(null);

  const [output, setOutput] = useState(null);

  const [panelVisibility, setPanelVisibility] = useState(false);

  // Events
  const onChange = (info) => {
    if (info.file.status !== 'removed' && info.file.type.includes('image')) {
      setPanelVisibility(true);

      let image = new Image();
      image.src = URL.createObjectURL(info.file);

      setFile(info.file);
      setImageUrl(image.src);
      return;
    }

    if (multiple) {
      setFiles(info.fileList);
      return false;
    }

    if (info.file.status === 'removed') {
      setFiles([]);
      setOutput(null);
    } else {
      setFiles([info.file]);
    }
    return false;
  };

  const onCropConfirmClick = async () => {
    if (typeof cropper !== 'undefined') {
      let url = cropper.getCroppedCanvas().toDataURL();

      await convertFileToUrl(url, file.name, file.type, (blobFile) => {
        setOutput(url);
        setFiles([blobFile]);
        setPanelVisibility(false);
      });
    }
  };

  const onCancelClick = () => {
    setPanelVisibility(false);
    setFiles([file]);
  };

  return (
    <Container>
      <FileDragger
        fileList={files}
        height={150}
        onChange={onChange}
        beforeUpload={() => false}
        accept={mimeTypes ?? ValidFileTypes.mimeTypes}
        multiple={multiple ?? false}
        style={{ marginTop: 20 }}
      >
        <UploadIcon />
        <Title>{t('ClickOrDragFile')}</Title>
      </FileDragger>

      {imageUrl && (
        <Modal width={500} title={t('ImageEditor')} visible={panelVisibility} onCancelClick={onCancelClick} onConfirmClick={onCropConfirmClick} templates={['colored']}>
          <Cropper
            style={{ height: 400, width: '100%' }}
            initialAspectRatio={1}
            src={imageUrl}
            ref={imageRef}
            viewMode={1}
            guides={true}
            minCropBoxHeight={10}
            minCropBoxWidth={10}
            background={false}
            responsive={true}
            checkOrientation={false}
            onInitialized={(instance) => {
              setCropper(instance);
            }}
          />
        </Modal>
      )}

      {output && (
        <Preview>
          <img src={output} alt="" />
        </Preview>
      )}
    </Container>
  );
}

const Container = styled.div`
  .ant-modal-wrap {
    z-index: 9999999;
  }
`;

const FileDragger = styled(Dragger)`
  background: ${(x) => x.theme.colors.white} !important;
`;

const UploadIcon = styled(InboxOutlined)`
  margin-bottom: 10px;
  font-size: 40px;
`;

const Title = styled.div`
  font-size: 15px;
  font-weight: bold;
`;

const Preview = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
  img {
    width: auto;
    max-width: 100%;
    box-shadow: 2px 2px 10px 0px ${(x) => x.theme.colors.lavenderGray} !important;
  }
`;
